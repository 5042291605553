import React from "react";
import "./ClientsPageStyles.css";
import diageo from "../assets/diageo.jpg";
import bidco from "../assets/bidco.jpg";
import chandaria from "../assets/chandaria.jpg";
import colgate from "../assets/colgate.jpg";
import dawalife from "../assets/dawalife.jpg";
import movit from "../assets/movit.jpg";
import naivas from "../assets/naivas.jpg";
import rabobank from "../assets/rabobank.jpg";
import heineken from "../assets/heineken.jpg";
import piron from "../assets/piron.jpg"

function ClientsPage() {
  return (
    <div className="clients-page">
    <h1 className="clients-heading">Some of Our Clients</h1>
    <div className="clients-container">
      
        <div className="client-item">
          <img src={movit} alt="Client 1" />
          
        </div>
        <div className="client-item">
          <img src= {bidco} alt="Client 2" />
        </div>
        <div className="client-item">
          <img src={rabobank} alt="Client 3" />
        </div>
        <div className="client-item">
          <img src={chandaria} alt="Client 4" />
        </div>
        <div className="client-item">
          <img src={naivas} alt="Client 5" />
        </div>
        <div className="client-item">
          <img src={diageo} alt="Client 6" />
        </div>
        <div className="client-item">
          <img src={colgate} alt="Client 7" />
         </div>
        <div className="client-item">
          <img src={dawalife} alt="Client 8" />
        </div>
        <div className="client-item">
          <img src={heineken} alt="Client 9" />
        </div>
        <div className="client-item">
          <img src={piron} alt="Client 10" />
        </div>
       
      
    </div>
    </div>
  )
}
export default ClientsPage;