import React from "react";
import './SurveyFormStyles.css';

function SurveyForm() {
return(
  <div>
<div className="survey-container">
  <h1>Paid Surveys</h1>
  <p>Complete online surveys for rewards</p>
  <h1><a href="https://forms.gle/5X2n6BHZuotYjVYt9" >Sign Up</a></h1>
  
</div>
<h2 className="title">Earning is easy as 1, 2, 3</h2>
<div className="numbered-circles">
 <div className="circle">
        <div className="number">1</div>
        <div className="text">Become a member</div>
      </div>
      <div className="circle">
        <div className="number">2</div>
        <div className="text">Answer surveys</div>
      </div>
      <div className="circle">
        <div className="number">3</div>
        <div className="text">Get your reward</div>
      </div>
    </div>
    <div className="content-container">
      <div className="first-content">
       <h1>Member</h1>
     <p>Generating income online begins IMMEDIATELY! Earn compensation for sharing your opinions on everyday products and services you frequently use.
       It requires only a brief time commitment and can be done conveniently from virtually anywhere online.
       Become part of our community by filling out the registration form, and the best part is that it's entirely cost-free to sign up!</p>
       </div>
       <div className="first-content">
       <h1>Answer Surveys</h1>
     <p>Participate in surveys and earn compensation for expressing your opinions about well-known and cherished products and brands.
       You'll receive an email or SMS notification when lucrative surveys become accessible. 
       Simply follow the link in your email or SMS to initiate a survey.
       Please ensure to furnish considerate responses and ensure the thorough completion of the entire survey.</p>
       </div>
       <div className="first-content">
       <h1>Get rewarded</h1>
     <p>
     Boost your engagement and watch your rewards flourish. After each survey, you'll be promptly rewarded with the agreed-upon monetary compensation. 
     Over time, your earnings will amass, unlocking an array of appealing incentives, including airtime, gift cards, and PayPal transfers.
      Your valuable input will pave the path to a gratifying journey – get involved with enthusiasm!
     </p>
       </div>
    </div>
  
</div>
)
}

export default SurveyForm;
