import React from "react";
import "./AboutUsStyles.css";
function AboutUs() {
  return (
    <div className="about-container">
  <div className="about-column">
    <h1 className="about-title">About Us</h1>
    <p className="about-paragraph">
      Segmetrix was founded to disrupt the conventional approach to conducting market research.
    </p>
   
    <p className="about-paragraph">
        Segmetrix is a combination of lean thinking, solid research expertise,
        advanced methodologies and strong orientation in business development.
        The quality and value of our work is built not only on the competence
         of the team, but also through our culture that brings wellbeing at work
         to the focus.
       </p>
  </div>

  <div className="about-column">
    <h2 className="about-subtitle">Our Approach</h2>
   
    <p className="about-paragraph">
        We make use of a wide range of quantitative and qualitative research
        techniques, selecting the most appropriate method to address the
        particular business problem at hand.
       </p>
      <p className="about-paragraph">
        Our exceptional expertise in various statistical methods such as conjoint
        analysis, cluster analysis, structural equation modeling, and Partial
         Least Squares, coupled with our profound understanding of behavioral
         sciences, distinguishes us. This enables us to comprehensively identify
        the emotional and rational drivers of human behavior from multiple
         perspectives.
       </p>
    
       <p className="about-paragraph">
         We employ automation in various aspects of data management to expedite
        the process and enable the team to concentrate on critical thinking and
        recognizing the business implications.
       </p>
       <p className="about-paragraph">
         Our philosophy is centered on close collaboration and constant feedback
         with the client from the outset. Unlike the conventional approach, our
         report is not the final deliverable. Instead, we work closely with the
        client's team to delve deeper into the findings and formulate a plan of
         action. We do this because it is the actions taken, not just the
         insights gained, that truly add value to the business.
      </p>
    
  </div>
</div>
  );
}

export default AboutUs;
