import React from "react";
import "./FooterStyles.css";
const Footer = () => {
  return(
    <div className = "footer">
      <div className="top">
        <div>
        <a href="/"><h1>Segmetrix</h1></a>
        </div>
        <div>
    <a href="https://twitter.com/segmetrix">
        <i className="fa-brands fa-x-twitter"></i>
    </a>
    <a href="https://www.linkedin.com/company/segmetrix-research-africa/">
        <i className="fab fa-linkedin"></i>
    </a>
    <a href="https://wa.me/+254729655162">
        <i className="fab fa-whatsapp"></i>
    </a>
</div>
      </div>
      <div className="bottom">
      <div>
    <h4>Address</h4>
    <a href="geo:0,0?q=Western+Heights+Westlands">
    <i class="fa fa-map-marker"></i> Victoria Plaza Westlands
</a>
    <a href="tel:+254729655162"><i class="fa fa-phone"></i> +2547 29655162</a>
    <a href="mailto:henry@segmetrix-research.com"><i class="fa fa-envelope"></i> henry@segmetrix-research.com</a>
</div>
        <div>
         <h4>Quick Links</h4>
         <a href="/about">About</a>
         <a href="/services">Services</a>
         <a href="/onlinepanel">OnlinePanel</a>
         <a href="/contacts">Contacts</a>
         
          </div>
          <div>
         <h4>Services</h4>
         <a href="/services#market-target-understanding">Market and target group understanding</a>
         <a href="/services#brand-development">Brand Development</a>
         <a href="/services#portfolio-performance">Portfolio Performance</a>
         <a href="/services#insight-audit">Insight audit</a>
          </div>
          <div>
          <h4>News and Insights.</h4>
          <input class="email-input" type="text" placeholder="Your Email"/>
					<button type="button" class="subscription">Subscribe</button>
          </div>
      </div>

    </div>
  )
}
export default Footer;