import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import AboutImg from "../assets/services.png";
import Footer from "../components/Footer";
import Solution from "../components/Solution";
function Services() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImg={AboutImg}
        title="Services"
        btnClass="hide"
      />
      <Solution />
      <Footer />
    </>
  );
}
export default Services;
