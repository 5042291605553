import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import panel from "../assets/background2.jpg";
import Footer from "../components/Footer";
import SurveyForm from "../components/SurveyForm";
function  OnlinePanel() {
  return (
    <>
      <Navbar />
      <Hero cName="hero-mid" heroImg={panel} title="Online Panel" btnClass="hide" />
      <SurveyForm />
      <Footer />
    </>
  );
}
export default OnlinePanel;
