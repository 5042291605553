import React, { useState } from 'react';
import './ContactFormStyles.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    industry: '',
    message: '',
  });

  const [formStatus, setFormStatus] = useState('Submit'); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus('Sending...');

    try {
      let response = await fetch("https://api.render.com/deploy/srv-ck7etqfsasqs73am909g?key=RXKO9eivhA8", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormStatus('Message Sent');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          industry: '',
          message: '',
        });
        setTimeout(() => {
          setFormStatus('Submit');
        }, 3000); 
        
      } else {
        setFormStatus('ERROR');
      }
    } catch (error) {
      console.error("Error:", error);
      setFormStatus('ERROR');
    }
    
  };

  return (
    <div className='contacts-page-container'>
      
      <div className='background-image-banner'>
        <div className='contacts-container'>
          <h2>Let's get started</h2>
          <h1>Contact Us</h1>
          <p>We would greatly appreciate the chance to showcase our cutting-edge solutions in the field to you.</p>
        </div>
      </div>

      <div className='formss'>
        <h3>Have a need for Segmetrix in mind?</h3>
        <h3>
         How can we help you? We are happy to answer your questions. Tell us more about your needs.
        </h3>
        
      </div>

      <div className='forms-container'>
      <p>
          Fill out this form to learn more about our solutions or schedule a demo
        </p>
        <form onSubmit={handleSubmit}>
          <label>First name:</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="First Name"
            required
          />
           <label>Last name:</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Last Name"
            required
          />
          <label>Email address:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="your email.com"
            required
          />
          <label>Phone number:</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="+xx xxx xxx xxx"
            required
          />
          <label>Your industry:</label>
          <select
            type="industry"
            name="industry"
            value={formData.industry}
            onChange={handleChange}
          >
            <option value="" disabled>
              Your industry:
            </option>
            <option value="Advertising Agency">Advertising Agency</option>
            <option value="Consulting">Consulting</option>
            <option value="Consumer Goods">Consumer Goods</option>
            <option value="Education">Education</option>
            <option value="Fashion, Luxury &amp; Sport">Fashion, Luxury & Sport</option>
            <option value="Food &amp; Beverage">Food & Beverage</option>
            <option value="PR Agency">PR Agency</option>
            <option value="Market Research Agency">Market Research Agency</option>
            <option value="Retail">Retail</option>
            <option value="Other">Other</option>
          </select>
          <label>Your message</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Message"
          ></textarea>
          <button type="submit">{formStatus}</button>
        </form>
      </div>

      <div className="form-and-columns-container">
        <div className="columns-container">
          <div className="column-box">
            <p>Our team will reply within the next 2 business hours with a quotation or next steps to help with your request.</p>
          </div>
        </div>
        <div className="columns-container">
          <div className="column-box">
          <p>
  If you prefer email or just would like to send us something, please use:
  <a href="mailto:henry@segmetrix-research.com">henry@segmetrix-research.com</a>
</p>

          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
