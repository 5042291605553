import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MenuItems } from "./MenuItems";
import "./NavbarStyles.css";

class Navbar extends Component {
  state = { clicked: false };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <nav className="NavbarItems">
        <div className="navbar-logo">
        <a href="/"><h1>Segmetrix</h1></a>
        </div>
        <div className="menu-icons" onClick={this.handleClick}>
          <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
          {MenuItems.map((item, index) => {
            return (
              <li key={index}>
                {item.subMenu ? (
                  <div className="dropdown">
                    <Link
                      className={item.cName}
                      to={item.url}
                      onClick={this.handleLinkClick}
                    >
                      <i className={item.icon}></i>
                      {item.title}
                    </Link>
                    <ul className="sub-menu">
                      {item.subMenu.map((subItem, subIndex) => (
                        <li key={subIndex}>
                          <Link
                            className="sub-menu-item"
                            to={subItem.url}
                            onClick={this.handleLinkClick}
                          >
                            {subItem.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <Link
                    className={item.cName}
                    to={item.url}
                    onClick={this.handleLinkClick}
                  >
                    <i className={item.icon}></i>
                    {item.title}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

export default Navbar;
