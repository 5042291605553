import React from "react";
import audit from "../assets/Audit.png"
import customer from "../assets/customerexperience.png"
import employee from "../assets/employees.png"
import target from "../assets/targetmarket.png"
import innovation from "../assets/innovation.png"
import marketing from "../assets/communication.png"
import portfolio from "../assets/portfolio.png"
import brand from "../assets/brand.png"
import "./SolutionStyles.css"
import SolutionData from "./SolutionData"
const Solution = () => {
  return(
    <div className="solution">
      <h1>Our services</h1>
      <p>Our goal is not only to generate insights into the market, customers, 
        and end-users but to collaborate with you to convert that understanding into actionable steps that 
        enhance the performance of your business.</p>
       <SolutionData
       className="first-sol"
       heading={
        <a href="#market-target-understanding">
          Market and target group understanding
        </a>        
      }
       text = "Developing a comprehensive understanding of the market is a fundamental prerequisite for all business growth. We aid you in acquiring the necessary knowledge about the market, competitive environment, and consumer/customer behavior to identify potential areas for expansion. This can be achieved through methods such as need and motive-based segmentation or in-depth examinations of specific target demographics."
       img1 ={target}
       />
       <SolutionData
       className="first-sol-reverse"
       heading={
        <a href="#brand-development">Brand Development</a>
      }
       text = "Establishing a powerful and significant brand differentiation is contingent on a profound comprehension of the target audience. Our research studies are geared towards comprehending not only your brand’s existing position, key strengths, and weaknesses in comparison to competitors, but also gaining deeper insights into the target group’s needs, motives, and aspirations. We strive to identify the primary drivers of their brand-related behavior to aid in building a more profound understanding of the target audience."
       img1 ={brand}
       />
       <SolutionData
       className="first-sol"
       heading = {
        <a href="#Customer experience development">Customer Experience Development</a>
       }
       text = "We assist you in developing customer experiences that encourage loyalty. Typically, this process involves mapping the customer journey and scrutinizing it to identify areas where improvements can be made. This can be accomplished through various means, including broad brand-level quantitative surveys, different qualitative methods, and more targeted assessments of specific stages of the journey or usability of specific services. Furthermore, we aid in comprehending overall customer satisfaction and Net Promoter Score (NPS). Ultimately, our goal is to ensure that you are well-informed about how to provide a consistent, brand-driven customer experience that boosts customer retention"
       img1 ={customer}
       />
        <SolutionData
       className="first-sol-reverse"
       heading = {
        <a href="#Portfolio performance">Portfolio Performance</a>
       } 
       text = "Spotting unmet customer needs can offer valuable opportunities for expanding your product or service offerings. Conducting research into unattended customer needs can also uncover other avenues for portfolio development. Additionally, we possess expertise in sophisticated pricing optimization and can aid you in identifying the most appropriate price point for your products and services."
       img1 ={portfolio}
       />
       <SolutionData
       className="first-sol"
       heading ={
        <a href="#Innovation and product development">Innovation and Product Development</a>
       } 
       text = "We have faith in innovation driven by the needs of the customers, and our aim is to assist you in achieving success in this area. We involve your target audience in generating and testing new concepts for products and services. We conduct tests at all necessary stages, including ideas, features, packaging, and even flavors, and help you predict the success of these innovations and determine how to improve them further."
       img1 ={innovation}
       />
       <SolutionData
       className="first-sol-reverse"
       heading = {
        <a href="#Marketing and communication impact">Marketing and Communication Impact</a>
       } 
       text = "We make sure that your marketing investments are maximized by conducting pre-tests and measuring campaigns, enabling you to continuously learn and improve."
       img1 ={marketing}
       />
       <SolutionData
       className="first-sol"
       heading = {
        <a href="#Insight audit">Insight Audit</a>
       }
       text = "To become more customer-driven, it’s essential for companies to define what it means to be customer-driven in their organization and how it should be reflected in their operations. We can assist in defining the target state and then evaluate the current state to identify areas for improvement and ways to strengthen customer orientation."
       img1 ={audit}
       />
       <SolutionData
       className="first-sol-reverse"
       heading = {
        <a href="#Employee experience">Employee Experience</a>
       } 
       text = "We have a strong interest in promoting well-being in the workplace and want to assist our clients in becoming leaders in this area. For this reason, we have transformed the most effective research methods into tools that can be used to develop the employee experience."
       img1 ={employee}
       />

      </div>
  )
}
export default Solution;



