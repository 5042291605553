export const MenuItems = [
  
    {
      title: "About",
      url: "/about",
      cName: "nav-links",
      icon: "fa-solid fa-circle-info"
    },
    {
      title: "Services",
      url: "/services",
      cName: "nav-links",
      icon: "fa-solid fa-briefcase",
      subMenu: [
        {
          title: "Market and target group understanding",
          url: "/services"
        },
        {
          title: "Brand development",
          url: "/services"
        },
        {
          title: "Customer experience development",
          url: "/services"
        },
        {
          title: "Portfolio performance",
          url: "/services"
        },
        {
          title: "Innovation and product development",
          url: "/services"
        },
        {
          title: "Insight audit",
          url: "/services"
        },
        
      ],
    },
    {
      title: "Online Panel",
      url: "/onlinepanel",
      cName: "nav-links",
      icon: "fa-solid fa-folder",
      subMenu: [
       {
        title: "Join Our Panel",
        url: "/onlinepanel"
       }
      ]
       },
    {
      title: "Contacts",
      url: "/contacts",
      cName: "nav-links",
      icon: "fa-solid fa-address-book",
      subMenu: [
        {
          title: "Get in touch",
          url: "/contacts"
        },
      ],
    }
  ];
  