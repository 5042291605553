import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import About from "./routes/About";
import Services from "./routes/Services";
import Contacts from "./routes/Contacts";
// import CookieConsent from "react-cookie-consent";
import OnlinePanel from "./routes/OnlinePanel";


export default function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/onlinepanel" element={<OnlinePanel />} />
        <Route path="/contacts" element={<Contacts />} />
        
      </Routes>
      {/* <CookieConsent debug={true}>This website uses cookies. See our<a href="/">Privacy Policy</a>for more</CookieConsent> */}
    </div>
  );
}
