import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Solution from "../components/Solution";
import Footer from "../components/Footer";
import AboutUs from "../components/AboutUs";
import backgroundimg from "../assets/background1.jpg";
import ClientsPage from "../components/ClientsPage"
function Home() {
  return (
    <>
      <Navbar />
      <Hero
        cName="hero"
        heroImg= {backgroundimg}
        title="Passion for Insights & Discovery"
        text="We make decision-making simple and easy"
      />
      <AboutUs />
      <Solution />
      <ClientsPage />
      <Footer />
    </>
  );
}
export default Home;
