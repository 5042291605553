import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";
function Contacts() {
  return (
    <>
      <Navbar />
      <ContactForm />
      <Footer />
    </>
  );
}
export default Contacts;
